<template>
  <v-card>
    <v-card-title class="pa-0">
      <div
        class="notif-badge"
        v-if="data.quotations_count && data.state !== 'done' && data.state !== 'in_progress'"
      >
        {{ data.quotations_count }}
      </div>
      <div class="notif-badge d-flex justify-center" v-if="data.pending_sign">
        <v-img src="@/assets/icons/sign.svg" max-width="25px" contain></v-img>
      </div>
      <div class="notif-badge d-flex justify-center" v-if="data.transaction_passed">
        <v-img src="@/assets/icons/payment.svg" max-width="25px" contain></v-img>
      </div>
      <v-list-item class="pa-0">
        <v-list-item-content class="pa-0">
          <v-list-item-title class="d-flex justify-space-between">
            <span class="ticket-name my-6 ml-6 text-truncate">{{ data.title }}</span>
            <span class="ticket-name my-6 ml-6 font-weight-thin blue-grey--text lighten-5">
              {{ data.id }}
            </span>
            <i class="blue lighten-1 pa-4 ticket-icon">
              <div class="unread-message" v-if="hasUnreadChatMessage">
                <v-img
                  src="@/assets/icons/ticket-notification-label.svg"
                  max-height="35px"
                  max-width="35px"
                ></v-img>
              </div>
              <v-img
                src="@/assets/icons/expertise.svg"
                v-if="
                  data.ticket_type === 'diagnostic' &&
                    data.state !== 'warranty_requested' &&
                    data.state !== 'on_warranty'
                "
                max-height="72px"
                max-width="72px"
              ></v-img>
              <v-img
                src="@/assets/icons/repair.svg"
                v-if="
                  data.ticket_type !== 'diagnostic' &&
                    data.state !== 'warranty_requested' &&
                    data.state !== 'on_warranty'
                "
                max-height="72px"
                max-width="72px"
              ></v-img>
              <v-img
                src="@/assets/icons/warranty.svg"
                v-if="data.state === 'warranty_requested' || data.state === 'on_warranty'"
                max-height="72px"
                max-width="72px"
              ></v-img>
            </i>
          </v-list-item-title>
          <v-list-item-subtitle class="mx-6 my-2">
            <span class="ticket-label text--secondary mr-2">{{ $t("State") }}:</span>
            <span class="font-weight-black my-4">{{ data.state | startCase }}</span>
            <span
              class="my-4 text-truncate"
              v-if="data.warranty_info && data.warranty_info.original_ticket_id"
            >
              ({{ data.warranty_info.description + "  " + data.warranty_info.original_ticket_id }})
            </span>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="mx-6 my-2">
            <span class="ticket-label text--secondary mr-2">{{ $t("Created at") }}:</span>
            <span class="font-weight-black my-4">{{ createdAt || "mm/dd/yyyy" }}</span>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="mx-6 my-2">
            <span class="ticket-label text--secondary mr-2">{{ $t("Valid to") }}:</span>
            <span class="font-weight-black my-4">{{ validToDate || "mm/dd/yyyy" }}</span>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="mx-6">
            <v-progress-linear
              :value="progress"
              color="primary"
              height="10"
              rounded
              reactive
            ></v-progress-linear>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="ma-6 "
            ><span class="ticket-label text--secondary mr-2">{{ $t("Required") }}:</span>
            <v-chip
              small
              class="ma-2"
              :class="{
                orange: data.required === 'expert',
                'teal lighten-2': data.required === 'technician'
              }"
              text-color="white"
              v-if="data.required"
            >
              {{ $t("label_" + data.required) }}
            </v-chip>

            <v-chip v-if="!data.required" small class="ma-2" text-color="white">
              {{ $t("No") }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-title>
    <v-card-actions class="pa-0" bottom>
      <v-btn class="ticket-btn" :color="btnColor" bottom block small :to="ticketUrl">
        {{ $t("Open") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";

import { keys, startCase } from "lodash";
import { mapState } from "vuex";
export default {
  name: "ticket-card",
  props: ["ticket", "type"],
  data: () => ({
    progress_: 0
  }),
  computed: {
    ...mapState("AllNotifsStore", ["allNotifs"]),
    btnColor() {
      return this.data.timer_ended ? "primary" : "gray";
    },
    hasUnreadChatMessage() {
      const idField = this.type === "bid" ? "quote_request_id" : "quotation_id";
      let orderHasUnreadMessages = false;
      for (const notif in this.allNotifs) {
        if (this.allNotifs[notif].type === "new_message" && !this.allNotifs[notif].read) {
          const quoteId = this.allNotifs[notif][idField];
          const ticketId = this.allNotifs[notif]["ticket_id"];
          orderHasUnreadMessages = this.ticket[quoteId] || this.ticket[ticketId];
        }
      }
      return Boolean(orderHasUnreadMessages);
    },
    data() {
      const data = this.ticket[keys(this.ticket)[0]];
      data.id = keys(this.ticket)[0];
      data.ticket_type = data.ticket_type ? data.ticket_type : "diagnostic";
      return data;
    },
    progress() {
      const validFrom = moment(this.data.valid_from);
      const validTo = moment(this.data.valid_to);
      const duration = moment.duration(validTo.diff(validFrom)).as("hours"); //100 %
      const durationLeft = moment.duration(validTo.diff(moment())).as("hours");
      return durationLeft > 0 ? (durationLeft / duration) * 100 : 100;
    },
    validToDate() {
      return moment(this.data.valid_to).format("MM/DD/YYYY  HH:mm");
    },
    createdAt() {
      return moment(this.data.valid_from).format("MM/DD/YYYY  HH:mm");
    },
    ticketUrl() {
      return {
        name: startCase(this.type),
        params: { id: this.data.id }
      };
    }
  }
};
</script>

<style lang="scss">
.ticket-icon {
  width: 72px;
  height: 72px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.ticket-name {
  font-size: 18px;
}
.ticket-label {
  font-size: 14px;
}
.ticket-text {
  height: 100px;
  overflow: auto;
}
.ticket-value {
}
.ticket-btn {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.notif-badge {
  width: 48px;
  height: 48px;
  border: 3px solid #fff;
  color: #fff;
  font-size: 24px;
  background-color: #4da046;
  border-radius: 24px;
  position: absolute;
  line-height: 48px;
  text-align: center;
  top: -24px;
  right: -24px;
  z-index: 1;
}

.unread-message {
  position: absolute;
  top: -15px;
  right: 55px;
  padding: 1px;
  display: block;
  border-radius: 50%;
}
</style>

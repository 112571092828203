var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[(_vm.data.quotations_count && _vm.data.state !== 'done' && _vm.data.state !== 'in_progress')?_c('div',{staticClass:"notif-badge"},[_vm._v(" "+_vm._s(_vm.data.quotations_count)+" ")]):_vm._e(),(_vm.data.pending_sign)?_c('div',{staticClass:"notif-badge d-flex justify-center"},[_c('v-img',{attrs:{"src":require("@/assets/icons/sign.svg"),"max-width":"25px","contain":""}})],1):_vm._e(),(_vm.data.transaction_passed)?_c('div',{staticClass:"notif-badge d-flex justify-center"},[_c('v-img',{attrs:{"src":require("@/assets/icons/payment.svg"),"max-width":"25px","contain":""}})],1):_vm._e(),_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"ticket-name my-6 ml-6 text-truncate"},[_vm._v(_vm._s(_vm.data.title))]),_c('span',{staticClass:"ticket-name my-6 ml-6 font-weight-thin blue-grey--text lighten-5"},[_vm._v(" "+_vm._s(_vm.data.id)+" ")]),_c('i',{staticClass:"blue lighten-1 pa-4 ticket-icon"},[(_vm.hasUnreadChatMessage)?_c('div',{staticClass:"unread-message"},[_c('v-img',{attrs:{"src":require("@/assets/icons/ticket-notification-label.svg"),"max-height":"35px","max-width":"35px"}})],1):_vm._e(),(
                _vm.data.ticket_type === 'diagnostic' &&
                  _vm.data.state !== 'warranty_requested' &&
                  _vm.data.state !== 'on_warranty'
              )?_c('v-img',{attrs:{"src":require("@/assets/icons/expertise.svg"),"max-height":"72px","max-width":"72px"}}):_vm._e(),(
                _vm.data.ticket_type !== 'diagnostic' &&
                  _vm.data.state !== 'warranty_requested' &&
                  _vm.data.state !== 'on_warranty'
              )?_c('v-img',{attrs:{"src":require("@/assets/icons/repair.svg"),"max-height":"72px","max-width":"72px"}}):_vm._e(),(_vm.data.state === 'warranty_requested' || _vm.data.state === 'on_warranty')?_c('v-img',{attrs:{"src":require("@/assets/icons/warranty.svg"),"max-height":"72px","max-width":"72px"}}):_vm._e()],1)]),_c('v-list-item-subtitle',{staticClass:"mx-6 my-2"},[_c('span',{staticClass:"ticket-label text--secondary mr-2"},[_vm._v(_vm._s(_vm.$t("State"))+":")]),_c('span',{staticClass:"font-weight-black my-4"},[_vm._v(_vm._s(_vm._f("startCase")(_vm.data.state)))]),(_vm.data.warranty_info && _vm.data.warranty_info.original_ticket_id)?_c('span',{staticClass:"my-4 text-truncate"},[_vm._v(" ("+_vm._s(_vm.data.warranty_info.description + " " + _vm.data.warranty_info.original_ticket_id)+") ")]):_vm._e()]),_c('v-list-item-subtitle',{staticClass:"mx-6 my-2"},[_c('span',{staticClass:"ticket-label text--secondary mr-2"},[_vm._v(_vm._s(_vm.$t("Created at"))+":")]),_c('span',{staticClass:"font-weight-black my-4"},[_vm._v(_vm._s(_vm.createdAt || "mm/dd/yyyy"))])]),_c('v-list-item-subtitle',{staticClass:"mx-6 my-2"},[_c('span',{staticClass:"ticket-label text--secondary mr-2"},[_vm._v(_vm._s(_vm.$t("Valid to"))+":")]),_c('span',{staticClass:"font-weight-black my-4"},[_vm._v(_vm._s(_vm.validToDate || "mm/dd/yyyy"))])]),_c('v-list-item-subtitle',{staticClass:"mx-6"},[_c('v-progress-linear',{attrs:{"value":_vm.progress,"color":"primary","height":"10","rounded":"","reactive":""}})],1),_c('v-list-item-subtitle',{staticClass:"ma-6 "},[_c('span',{staticClass:"ticket-label text--secondary mr-2"},[_vm._v(_vm._s(_vm.$t("Required"))+":")]),(_vm.data.required)?_c('v-chip',{staticClass:"ma-2",class:{
              orange: _vm.data.required === 'expert',
              'teal lighten-2': _vm.data.required === 'technician'
            },attrs:{"small":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("label_" + _vm.data.required))+" ")]):_vm._e(),(!_vm.data.required)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("No"))+" ")]):_vm._e()],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pa-0",attrs:{"bottom":""}},[_c('v-btn',{staticClass:"ticket-btn",attrs:{"color":_vm.btnColor,"bottom":"","block":"","small":"","to":_vm.ticketUrl}},[_vm._v(" "+_vm._s(_vm.$t("Open"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
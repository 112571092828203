<template>
  <v-window-item :value="activeTab">
    <v-container fluid class="px-6 ">
      <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
      <v-row v-if="items.length">
        <v-col>
          <v-row>
            <v-col :key="item.id" v-for="item in items" md="4" sm="6">
              <ticket-card :ticket="item" :type="type"></ticket-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- todo : fix pagination -->
      <v-row class="my-6" v-if="!items.length" align="center" justify="center">
        <v-alert icon="mdi-folder" prominent outlined color="primary">
          {{ $t("Have no tickets yet", { tab: $t(tab) }) }}
        </v-alert>
      </v-row>
      <v-row align-self="end" v-if="showPagination">
        <v-col>
          <div class="text-center">
            <v-pagination v-model="page" :length="pagesLength" circle></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-window-item>
</template>

<script>
import { getActiveOrders, getActiveBids } from "@/api";
import TicketCard from "@/pages/Projects/TicketCard";

export default {
  name: "projects-tickets-list",
  components: { TicketCard },
  props: ["tab"],
  data: () => ({
    loading: false,

    items: [],
    type: null,
    page: 1,
    pagesLength: 1
  }),
  computed: {
    activeTab() {
      return this.tab;
    },
    showPagination() {
      // return !this.items.length && !this.loading && this.page == 1;
      // todo fix pagination
      return true;
    }
  },
  mounted() {
    this.getTicksets();
  },
  methods: {
    getTicksets() {
      this.loading = true;
      if (this.$route.path.search("order") > 0) {
        this.type = "ticket";
        getActiveOrders({ state: this.tab, page: this.page })
          .then(r => {
            this.items = r.data.orders;
            this.pagesLength = this.items.length
              ? Math.ceil(+r.data.pagination.total / +r.data.pagination.per_page)
              : 1;
          })
          .catch(e => {
            console.error(e);
          })
          .finally(() => (this.loading = false));
      } else {
        this.type = "bid";
        getActiveBids({ state: this.tab, page: this.page })
          .then(r => {
            this.items = r.data.bids;
            this.pagesLength = this.items.length
              ? Math.ceil(+r.data.pagination.total / +r.data.pagination.per_page)
              : 1;
          })
          .catch(e => {
            console.error(e);
          })
          .finally(() => (this.loading = false));
      }
    },
    routeChange() {
      this.page = 1;

      this.getTicksets();
    }
  },
  watch: {
    $route: "routeChange",
    page: "getTicksets"
  }
};
</script>
